import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import "../css/article.css"

function Cookies() {

  return (
    <Layout>

      <Seo title="Declaração de uso de cookies" description="O que são Cookies? Os cookies são pequenos arquivos (do seu navegador) que armazenam temporariamente o que o internauta está visitante em nosso website. São bytes adicionados no seu computador que não ocupam praticamente nenhum espaço no disco rígido. Esse arquivo ficará acessível a nossos servidores para coleta de dados" />

      <div className="postContent mt-32 mb-10 mx-auto max-w-xs md:max-w-screen-md">
        <h1>POLÍTICA DE COOKIES</h1>
        <h2>O que são Cookies?</h2>
        <p>Os cookies são pequenos arquivos (do seu navegador) que armazenam temporariamente o que o internauta está visitante em nosso website. São bytes adicionados no seu computador que não ocupam praticamente nenhum espaço no disco rígido.&nbsp;&nbsp;</p>
        <p>Esse arquivo ficará acessível a nossos servidores para coleta de informações que nos permitirão aprimorar a usabilidade e a experiência de navegação em nosso site, blog e sistema, sendo utilizados para oferecer uma melhor qualidade do serviço, rastrear informações, armazenar preferências, etc. Estes cookies não coletam outras dados pessoais ou informação de qualquer natureza armazenadas em seu computador, não se preocupe.</p>
        <h2>Quais cookies utilizamos?</h2>
        <p>Empregamos cookies na operação do nosso website (domínio www.quantosobra.com.br), blog (subdomínio blog.quantosobra.com.br) e sistema (subdomínio app.quantosobra.com.br).</p>
        <p>Cookies necessários/obrigatórios: para navegação no site e acesso aos recursos. Sem ele, os serviços não funcionam corretamente. Eles não podem ser desabilitados por você.</p>
        <p>Cookies funcionais: melhora as preferências e escolhas para a navegação. Quando desativados, podem impossibilitar o uso de algumas funções.</p>
        <p>(i) informações técnicas como o tipo de equipamento que você utiliza (Desktop, celular, tablete ou outros), o sistema operacional empregado, o navegador utilizado e o endereço de IP. Estes dados são empregados apenas para fins estatísticos e para que possamos corrigir problemas técnicos ou aprimorar a experiência de navegação;</p>
        <p>(ii) informações sobre a experiência de navegação em nosso website, blog e/ou sistema, como por exemplo as seções visitadas, conteúdo e serviços visualizados, o tempo de visualização, referência externas que o levaram a nosso website, blog e/ou sistema etc. Estas informações são empregadas para que possamos entender que tipo de conteúdo desperta maior interesse e levar a produzir materiais e conteúdos mais assertivos para os usuários. Além disso, servem para que possamos estudar o comportamento em nosso sistema (de forma anônima) visando entregar sempre a melhor solução possível aos nossos clientes, criando projetos de melhorias em nossas soluções com base em dados reais;</p>
        <p>(iii) informações de natureza pessoal como idioma de navegação e país de origem, utilizado no propósito de adotar essas preferências como padrão a cada nova visita sua em nossos portais.</p>
        <p>Cookies de marketing: direcionam anúncios de acordo com os interesses e limitam a quantidade de vezes que o anúncio aparece.</p>
        <p>Não empregamos nenhum cookie próprio. Para ver todos os Cookies utilizados, <a href="javascript:window.sendAdoptCommand('open')">clique aqui</a>.</p>
        <h2>Finalidade de uso de cookies e Dados pessoais coletados</h2>
        <p>Os cookies poderão ser utilizados para segmentar os usuários em grupos de interesses mútuos, personalizar a comunicação textual/visual e oferecimento de conteúdos e produtos condizentes com os seus interesses, além das interações dos serviços da AWISE como como publicidade, marketing e outros para aprimorar os serviços oferecidos aos clientes.</p>
        <p>Você poderá aceitar ou recusar o uso de cookies em seu computador, a partir da configuração do seu navegador. Todavia, para utilizar algumas funcionalidades do site, alguns cookies são necessários.</p>
        <p>Cookies necessários: para que o site funcione corretamente. Os dados são anônimos e não são rastreáveis.</p>
        <p>Cookies funcionais: para realizarmos otimizações e oferecer um melhor serviço e melhor experiência em nosso website, blog e aplicativo.. Os dados são anônimos e não são rastreáveis.</p>
        <p>Cookies de marketing: personalização de comunicação textual com os usuários, oferecimento de produtos condizentes com os interesses do usuário. Dados coletados: E-mail, nome, telefone.</p>
        <h2>Armazenamento de cookies</h2>
        <p>Os cookies utilizados são: Google Analytics; Hotjar; ActiveCampaign; Youtube; Facebook; Yandex; Crisp; Huggy; Pipz; Instagram; Pinterest; Mixpanel; Google Tag Manager; Google Optmize; Youtube;</p>
        <p>As informações coletadas referentes ao endereço de IP e aos registros de acesso dos Clientes são armazenadas por pelo menos 6 (seis) meses, conforme determinado pelo artigo 15 da Lei nº 12.965/2014 (Marco Civil da Internet).</p>
        <h2>Gerenciamento de Cookies</h2>
        <p>Os cookies necessários/obrigatórios serão instalados para o funcionamento dos serviços do nosso site. Contudo, a instalação dos demais cookies está sujeita ao seu consentimento. Ressaltamos que o bloqueio de cookies pode resultar na perda de funcionalidades ou restrição de acesso a seções de nosso website.</p>
        <p>As configurações para instalação são obtidas quando de seu primeiro acesso a nosso website. Caso você queira alterar suas preferências, basta clicar nas configurações dos cookies poderão ser alteradas a qualquer tempo por você, basta ir nas configurações do seu navegador para isso.&nbsp;</p>
        <p>Cada navegador possui configurações diferentes, para acessar o uso destes navegadores, clique nos links abaixo:</p>
        <ul>
          <li>Se você usa o <a href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a>.</li>
          <li>Se você usa o <a href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s">Firefox</a>.</li>
          <li>Se você usa o <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">Safari</a>.</li>
          <li>Se você usa o <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;oco=1&amp;hl=pt-BR">Google Chrome</a>.</li>
          <li>Se você usa o <a href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies">Microsoft Edge</a>.</li>
          <li>Se você usa o <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>.</li>
        </ul>
        <p>Para ver o aviso de Cookies novamente e modificar o seu consentimento ou até mesmo revogar o consentimento, clique no link que segue: <a href="javascript:window.sendAdoptCommand('open')">Mostrar Aviso</a>.</p>
        <h2>Cookies em Links e Conteúdo de Terceiros</h2>
        <p>Como plataforma de conteúdo focado em gestão empresarial, é possível que disponibilizemos conteúdo de terceiros em nosso blog, a exemplo de vídeos, imagens, artigos e pesquisas de serviços de mídia social como Instagram, Linkedin, Facebook, Medium, Youtube, Itunes, etc. Ao acessar o conteúdo desses provedores, é provável que cookies destes terceiros sejam igualmente instalados em seus dispositivos. Não possuímos controle e gestão sobre o uso, finalidade e funcionamento destes cookies. Recomendamos que busque conhecer a política de cookies desses terceiros para mais informações.</p>
        <p>Informação do Encarregado</p>
        <p>Para mais informações, acesse nossa <a href="https://www.quantosobra.com.br/politica-de-privacidade-e-seguranca/">Política de Privacidade.</a></p>
        <p>Nome: Maríllia dos Santos Dias</p>
        <p>E-mail: dpo@quantosobra.com.br</p>
        <p>Quaisquer solicitações, informações e esclarecimentos poderão ser encaminhados diretamente pelo formulário acima indicado.</p>
        <h2>Informação sobre a possibilidade de alteração da política</h2>
        <p>Nós poderemos atualizar esta Política de Cookies periodicamente com o objetivo de refletir a Governança de Dados da AWISE. Assim, todas as nossas políticas terão data de atualização.&nbsp;</p>
        <p>Quando realizarmos alguma mudança substancial, faremos um aviso no site/sistema para que você tenha conhecimento.</p>
        <p>CESSÃO DE DIREITOS</p>
        <p>Independentemente de qualquer aviso ou notificação ao cliente, poderemos ceder os direitos oriundos desta Política de Cookies, no todo ou em parte, a outras empresas, oriundas de transações societárias, como fusão, aquisição, ou joint-venture, a qualquer momento, desde que observada a legislação vigente, regulamentos e normas aplicáveis a esta.</p>
      </div>

    </Layout>
  )
}

export default Cookies