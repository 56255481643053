import React from "react"

export default function FooterItem({ data }) {

  return (
    <ul className="flex flex-col md:flex-row md:flex-wrap xl:justify-between gap-7 m-0 text-white text-left">
      {data.map((item) =>

        <li className="flex flex-col gap-1">

          <h3 className="font-semibold text-xl mb-1">{item.title}</h3>

          {item.items.map((link) =>

            <a className="text-black-200 text-sm hover:underline transition" target="_blank" href={link.link}>
              {link.text}
            </a>

          )}

        </li>

      )}
    </ul>
  )
}