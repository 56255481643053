import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import CategorySection from "../../layout/categorySection"
import FooterItem from "./item";
import Politicas from "./politicas";

import logo from "../../../images/newLayout/footerLogo.svg";

import "./footer.css"

function Footer() {

  const pageQuery = useStaticQuery(graphql`
    query FooterPageQuery {
      strapiRodapeQs {
        tituloLink {
          titulo
          link {
            texto
            link
          }
        }
        materiais {
          titulo
          link {
            texto
            link
          }
        }
        social {
          iconeLink {
            link
            imagem {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const footerData = pageQuery.strapiRodapeQs.tituloLink.map((item) => ({
    title: item.titulo,
    items: item.link.map((link) => ({
      text: link.texto,
      link: link.link
    }))
  }))
  const materialsData = pageQuery.strapiRodapeQs.materiais.map((item) => ({
    title: item.titulo,
    items: item.link.map((link) => ({
      text: link.texto,
      link: link.link
    }))
  }))
  const socialData = pageQuery.strapiRodapeQs.social.iconeLink.map((item) => ({
    link: item.link,
    icon: item.imagem.localFile.publicURL
  }))

  return (
    <footer className="bg-black-800 text-white pt-8 pb-6">

      <div className="flex flex-col w-full mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">

        <div className="flex flex-col lg:flex-row gap-11 xl:gap-20 mb-11">
          <Link to="/"><img alt="logo awise" loading="lazy" className="w-56" src={logo} /></Link>
          <div className="hidden xl:flex h-32 w-1 bg-black-700 rounded-full" />
          <FooterItem data={footerData} />
        </div>

        <div className="flex flex-col gap-5 mb-14">
          <h2 className="m-0 font-semibold text-2xl">Gestão e empreendedorismo na prática</h2>
          <FooterItem data={materialsData} />
        </div>

        <div className="flex flex-col lg:flex-row items-center xl:justify-between gap-8 w-full">
          <p className="text-sm text-center md:text-left text-black-200">QuantoSobra, um blog Awise | Awise - Sistema de Gestão Grátis - ERP para Micro e Pequenas Empresas</p>
          <div className="flex flex-row gap-4">
            {socialData.map(item =>
              <a className="iconTeste flex items-center justify-center w-11 h-11 border border-solid border-black-700 rounded-full transition" href={item.link}>
                <img className="hover:shadow-2xl" src={item.icon} alt={`icone do ${item.social}`} loading="lazy" />
              </a>
            )}
          </div>
        </div>

        {/*<section className="w-full md:w-96 flex flex-col gap-2 text-sm">
          <CategorySection />
          <Politicas />
        </section>*/}

      </div>

    </footer>
  )
}

export default Footer