import React from "react"
import { Link } from "gatsby"

import data from "../../../data/politicsData.json"

function Politicas() {

  return (
    <>
      <h5 className="font-semibold text-center md:text-left">Privacidade</h5>
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-y-1 md:gap-0 text-center md:text-left">

        {data.map((item, i) => 
          <Link to={item.anchor} className="hover:text-greenQS transition">
            {item.message}
          </Link>
        )}

      </ul>
    </>
  )
}

export default Politicas