import React from "react"
import { Link } from "gatsby"

import logo from "../../images/layout/logo-principal.svg";
import search from "../../images/layout/search-icon.svg";

function Header() {

	const ctaUrl = "https://www.useawise.com/?utm_source=blogqs&utm_medium=referral&utm_campaign=blogqs_header_sistemadegraca&utm_marketing_tactic=aquisicao"
	const freeMaterialUlr = "https://materiais.quantosobra.com.br/?utm_source=blogqs&utm_medium=referral&utm_campaign=blogqs_header_materiaisgratuitos&utm_marketing_tactic=aquisicao"

	return(

		<header className="header fixed h-16 xl:h-20 w-full bg-white shadow inset-0 z-20">

			<div className="flex flex-row justify-center xl:justify-between items-center h-full text-lg font-medium z-10 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">

				<div className="flex flex-row items-center space-x-12">
					<a href="javascript:navigateToHome()"><img className="flex w-44" src={logo} alt="Logo da Quanto Sobra"/></a>
					<Link className="hidden xl:flex" to="/category/planilhas-gratis/">Planilhas Grátis</Link>
					<a className="hidden xl:flex" href={freeMaterialUlr}>Materiais Gratuitos</a>
				</div>

				<div className="hidden xl:flex flex-row items-center gap-7">
					<a className="flex h-8 px-6 py-0.5 bg-greenQS rounded text-white text-center capitalize font-semibold" href={ctaUrl}>
						Testar sistema de gestão DE GRAÇA
					</a>
					<a href="javascript:setHeaderVisibility()"><img className="flex h-10" src={search} alt="Icone de pesquisa"/></a>
				</div>

				<div className="block xl:hidden absolute right-8">
					<a href="javascript:setSandwichMenuVisibility()" className="flex items-center text-black">
						<svg className="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
					</a>
				</div>

			</div>

			<div className="sandwichMenu hidden relative xl:hidden w-screen h-screen pt-20 flex-col items-center gap-20 z-10 bg-white text-2xl font-medium text-center">

				<a href="javascript:navigateToPlanilhasGratis()">Planilhas Grátis</a>
				<a href={freeMaterialUlr}>Materiais Gratuitos</a>
				<button><img className="flex xl:hidden h-10" src={search} alt="Icone de pesquisa"/></button>
				<a className="px-4 bg-greenQS rounded text-white text-center capitalize" href={ctaUrl}>
					Testar sistema de <br /> gestão <strong className="uppercase font-medium">de graça</strong>
				</a>

				<div className="absolute bottom-0 h-24 bg-greenQS w-full"></div>
			</div>

		</header>
	)
}

export default Header