import React from "react"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "../newLayout/footer"

function Layout( { children } ) {

  function addCookieScriptIfIsProductionEnvironment() {
    if (process.env.GATSBY_IS_PRODUCTION_ENVIRONMENT) {
      return (
        <Helmet>
          <meta name="adopt-website-id" content="a0cc5f5f-b5fb-4026-829c-f488471a118b" />
          <script defer src="https://tag.goadopt.io/injector.js?website_code=a0cc5f5f-b5fb-4026-829c-f488471a118b" class="adopt-injector" />
        </Helmet>
      )
    }
    return
  }

  return (

    <div id="start" className="flex flex-col text-textQS font-display">

      {addCookieScriptIfIsProductionEnvironment()}

      <Header />

      <main className="flex flex-col">{children}</main>

      <Footer />

    </div>
  )
}

export default Layout